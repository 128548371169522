import React, { FC, useContext, useEffect } from 'react';
//import Card from '@mui/material/Card';
//import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
//import Link from '@mui/material/Link';
import { Chip, Tooltip, /*Box,  Tooltip, Divider, Stack, Paper, SvgIcon*/ } from '@mui/material';
//import { green } from '@mui/material/colors';
//import Grid from '@mui/material/Grid';
//import { GetPinIcon, GetPinName } from '../map/MapFilter'
import { useDispatch } from 'react-redux';
import { getSearchByCoordinates } from "../../features/searchSlice";
import Button from '@mui/material/Button';
//import { ReactComponent as CaregiverSVG } from '../../assets/caregiver.svg';
//import { ReactComponent as MMWarriorSVG } from '../../assets/patienticon.svg';
import { Stack } from '@mui/material';
//import Container from '@mui/material/Container';
import { setAnalyticUsersClicked, /*leocentersetCenter,*/ setPublicPinClicked } from "../../features/dataProcessingSlice"
import { GetUserTypePin } from '../MessageProfile'
import { activateMarker } from '../../features/activeMarkers';
import { AISummaryContext } from '../AI/AISummary';
import { DirectChatUsers, useSetChatUserMutation } from '../../features/ChatSlice';
import { StartChat } from '../markers/MMMarkers';
import { useNavigate } from 'react-router-dom';
import { useAuthProfile } from '../../app/hooks';
import { LoadingButton } from '@mui/lab';
import { SendbirdChatButtonProps } from '../messaging/SendbirdChatButton';
import ChatIcon from '@mui/icons-material/Chat';

export interface CareCenterInfo {
    center_name: string,
    cancer_center_type: string,
    designation: string,
    address: string,
    lat: number,
    lng: number,
    street: string,
    city: string,
    state: string,
    region: string,
    country: string,
    phone: string,
    website: string,
    id: number,
    distance: number,
    count_in_radius: number,
    count_total: number
}

export interface PatientCardProps {
    patientInfo: any,
    align: string,
}

export const PatientCard: FC<PatientCardProps> = ({ patientInfo, align }) => {
    const { showViewUserStoryAnalytics, showMessageButton, chatMode } = useContext(AISummaryContext);
    const { authStatus, profile: profileLoggedIn, isLoading: isLoadingpProfileLoggedIn, isLoading: isLoadingProfileLoggedIn, isError: isErrorProfileLoggedIn, error: errorProfileLoggedIn, userAttributesStateStatus } = useAuthProfile()
    const [SetChat, { isLoading: isLoadingSetChat, isSuccess: isSuccessSetChat, isError: isErrorSetChat, error: errorSetChat, data: setChatData, reset }] = useSetChatUserMutation()
    const navigate = useNavigate()
    const dispatch = useDispatch<any>()
    const distanceInMiles = (patientInfo.distance ?? 0) / 1609.34;
    const formattedNumber = new Intl.NumberFormat('en-US', {
        style: 'decimal',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    }).format(distanceInMiles);

    function handleProfileClick() {
        if (!chatMode) {
            dispatch(getSearchByCoordinates({ lat: patientInfo.lat, lng: patientInfo.lng }));            
            dispatch(setAnalyticUsersClicked(false))  
            dispatch(activateMarker({ id: patientInfo.id, disableHover: true }))
            dispatch(setPublicPinClicked(patientInfo.id))
        } else {
            StartChat( [profileLoggedIn.id,  patientInfo.id ], authStatus, () =>  navigate("/Messages"),  
            (setChatArg: DirectChatUsers)=> {
              SetChat(setChatArg)
          })
        }        
    }

    useEffect( () => {
        if (isSuccessSetChat && chatMode) {            
            dispatch(setAnalyticUsersClicked(false)) 
            navigate("/Messages", { state: { chatAndUserData: setChatData } })
          }
    }, [isSuccessSetChat, setChatData, navigate])

        return (
            <Stack sx={{ border: 1, borderRadius: 1, borderColor: 'rgba(0, 0, 0, 0.12)', mb: 1, p: 1, width: '100%' }} alignItems={align} direction="column">
                <Stack alignItems="center" direction="row">                             
                    {chatMode && <ChatIcon sx={{ color:'#656EFF', mr: '10px'}}/> }
                    <Tooltip title={chatMode?"Start a chat":"See Pin"} placement='top-start' arrow sx={{ backgroundColor: 'white', color: 'black', fontSize: '1.2rem' }}>
                    <LoadingButton loading={isLoadingProfileLoggedIn ||isLoadingSetChat} onClick={handleProfileClick} variant="text" sx={{ paddingLeft: isLoadingProfileLoggedIn ||isLoadingSetChat ? '25px' : '0px' , overflow: 'hidden', textOverflow: 'ellipsis' }}>{patientInfo.name_public}</LoadingButton>
                    </Tooltip>
                    {GetUserTypePin(patientInfo.type, { style: { height: 30, width: 30 } }).icon}                    
                    <Chip style={{ marginLeft: 'auto' }} size="small" label={(patientInfo.distance !== null && patientInfo.distance !== undefined) ? formattedNumber.toString() + ' miles' : "Location unknown"} color="success" sx={{ backgroundColor: 'rgba(0, 0, 255, 0.5)' }} />                                                    
                </Stack>
                {patientInfo.formattedsearchterm &&
                    <Typography variant="caption" sx={{ pb: 1, color: '#777777' }}>
                        {patientInfo.formattedsearchterm}
                    </Typography>
                }
                {patientInfo.variable &&
                    <Typography variant="body2" sx={{ pb: 1, color: '#777777' }}>
                        {`${patientInfo.variable} : ${patientInfo.value}`}
                    </Typography>
                }
            </Stack>

        );
    };

//{patientInfo.variable.toLowerCase().split("_").map((word : any) => word.charAt(0).toUpperCase() + word.slice(1)).join(" ") + " : " + patientInfo.value}

// {"address" in patientInfo &&
//     <Typography variant="caption" sx={{ pb: 1, color:'#777777' }}>
//         {patientInfo.address}
//     </Typography>
// }
// {"variable" in patientInfo &&
//     <Typography variant="body2" sx={{ pb: 1, color:'#777777' }}>
//         {patientInfo.variable.toLowerCase().split("_").map((word : any) => word.charAt(0).toUpperCase() + word.slice(1)).join(" ") + " : " + patientInfo.value}
//     </Typography>
// }
