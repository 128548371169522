import React, { FC } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { AccordionSummary, AccordionDetails, Accordion, /*LinearProgress,*/ CircularProgress, Alert } from '@mui/material';
import { /*useGetProfilesForPointQuery,*/ useGetProfilesPinDataForDefaultPointQuery } from '../../features/profilesSlice';
import { /*useSelector, useDispatch,*/ shallowEqual, useSelector } from 'react-redux';
import { FetchBaseQueryError, QueryStatus } from '@reduxjs/toolkit/query';
import { Patients } from '../patient/Patients';
import { Box } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useAuthProfile } from "../../app/hooks"
import { RootState } from '../../app/store';
import { PatientCard } from './PatientCard';
export const StorySearchSummary: FC<any> = () => {
    //const searchStoryState: any = useSelector<any>(state => state.search);
    const { authStatus } = useAuthProfile()

    const lastgetSearchByStoryyStatusArgsAndData = useSelector((state: RootState) => {
        // Access all queries
        const queries = state.aiAnalytics.queries;    
        // Find the last query key matching the "getPosts" endpoint
        const lastQueryKey = Object.keys(queries)
          .filter((key) => key.startsWith('getSearchByStory'))
          .pop();         
          const argsString = lastQueryKey  ? lastQueryKey.slice(lastQueryKey.indexOf('(') + 1, lastQueryKey.lastIndexOf(')'))  : null;
          const args = argsString?JSON.parse(argsString):undefined;
        //   declare enum QueryStatus {
        //     uninitialized = "uninitialized",
        //     pending = "pending",
        //     fulfilled = "fulfilled",
        //     rejected = "rejected"
        // }
        const status: QueryStatus | undefined= lastQueryKey ? queries[lastQueryKey]?.status : undefined
        // Safely return data from the last query, if it exists
        return { status: status, args: args, data: lastQueryKey ? queries[lastQueryKey]?.data as any | undefined : undefined}
      });
    
    return (
        <>
        {lastgetSearchByStoryyStatusArgsAndData.data?.length > 0 &&
            <>
            <Typography variant="h6">
                Search Results:
            </Typography>
            <Accordion 
                disableGutters 
                defaultExpanded 
                elevation={0} 
                sx={{ 
                    border: 1, 
                    borderRadius:8, 
                    borderColor: '#E0E0E0', 
                    borderStyle: 'solid',
                    '&:before': {
                        display: 'none',
                    }
                }}
            >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Card elevation={0} sx={{ width: '100%', }}>
                        <CardContent >
                            <Typography variant="subtitle1" sx={{fontWeight:'bold'}}>
                                {lastgetSearchByStoryyStatusArgsAndData.data.length} Member{lastgetSearchByStoryyStatusArgsAndData.data.length > 1 ? "s" : ""} found for:
                            </Typography>
                            <Typography variant="subtitle1">
                                {lastgetSearchByStoryyStatusArgsAndData.args.story}
                            </Typography>
                            {authStatus === 'unauthenticated' && 
                                <Typography variant="subtitle1" color="primary">
                                    Sign in or create a free account to get more results
                                </Typography>
                            }
                        </CardContent>
                    </Card>
                </AccordionSummary>
                <AccordionDetails>
                    <Patients patientsData={lastgetSearchByStoryyStatusArgsAndData.data ?? []} pageCap={5} align={"left"}  renderPatientCard={(row: any)=> <PatientCard patientInfo={row} align={"left"}   key={row.id} />}/>
                </AccordionDetails>
            </Accordion>
            </>
        }
        {lastgetSearchByStoryyStatusArgsAndData.status === QueryStatus.pending && <CircularProgress sx={{m:'auto'}}/>}
        {lastgetSearchByStoryyStatusArgsAndData.status === QueryStatus.rejected && <Alert sx={{m:'auto'}} severity="error">Oops... Sorry, an error occurred while retrieveing search results.  Please try again later.</Alert>}
        </>
    ) 
}

