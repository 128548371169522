import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { CircularProgressCentered, HeaderContent } from '../../app/sharedcomponents';
import { useAuthProfile } from "../../app/hooks"
import { Alert, Box, Link, Skeleton, Typography, Button } from '@mui/material';


import { useLocation } from 'react-router-dom';

import { NoChatsMessage } from './NoChatsMessage';
import { AppDispatch } from '../../app/store';
import { printError } from '../../app/utils';
import { useEneterOpenChannel } from './hooks';
import { UnAuthorizedMessage } from './ChatMain';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';


//import { OpenChats } from './open/OpenChats';
import CampaignIcon from '@mui/icons-material/Campaign';
import Stack from '@mui/material/Stack';
import './announcementchannel.css';
import { useNavigate } from "react-router-dom";
import Paper from '@mui/material/Paper';

import { CoreMessageType } from '@sendbird/uikit-react/types/utils';
import { App as SendbirdApp, useSendbirdStateContext, } from '@sendbird/uikit-react';
import '@sendbird/uikit-react/dist/index.css';
import OpenChannel from '@sendbird/uikit-react/OpenChannel';
//import {OpenChannelUIProps, OpenChannelProviderProps} from '@sendbird/uikit-react/OpenChannel';
import { OpenChannelProvider, useOpenChannelContext } from '@sendbird/uikit-react/OpenChannel/context';
import { AdminMessage, BaseMessage, FileMessage, MessageListParams, MultipleFilesMessage, Reaction, UploadedFileInfo, UserMessage } from '@sendbird/chat/message';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { GetAnnouncementsChannelUrlForEnvironment } from '../LeftDrawer';


export const OpenChannelMain: React.FC<{ channelUrl: string }> = ({ channelUrl }) => {
    const [isInitialized, setInitialized] = useState<boolean>(true)
    const { authStatus, profile: loggedInUserProfile, isLoading: isLoadingLoggedInUserProfile, isSuccess: isSuccessLoggedInUserProfile, isError: isErrorLoggedInUserProfile, error: errorLoggedInUserProfile, userAttributesStateStatus } = useAuthProfile()
    //const isLoggedInUserProfile = isSuccessLoggedInUserProfile ? (publicPinSelected ? loggedInUserProfile.id === publicPinSelected : true) : undefined

    const componentRef = useRef<HTMLDivElement>(null);
    const dispatch = useDispatch<AppDispatch>()

    //const onStartChat = useEneterOpenChannel(chatAndUserData?.channelUrl, () => setOpenChannelEntered(true));
    const [openChannelEntered, setOpenChannelEntered] = useState<{ isInitialized?: boolean, isLoading?: boolean, isError?: boolean, isSuccess?: boolean, error?: any }>({ isInitialized: false, isLoading: false, isError: false, isSuccess: false })
    const { stores } = useSendbirdStateContext(); // Access the Sendbird state context
    const sdk = stores.sdkStore.sdk; // Get the Sendbird SDK instance
    const [showInput, setShowInput] = useState('none')
    const navigate = useNavigate()
    /*
    useEffect(() => {
        let text = Array.from(document.getElementsByClassName('sendbird-openchannel-footer') as HTMLCollectionOf<HTMLElement>)
        text.forEach(row => {
            if (authStatus != "authenticated") {
                row?.style.setProperty('display', 'none', 'important')
            } else {
                row?.style.setProperty('display', 'none', 'important')
            }
        })
    }, [])
    */
    useEffect(() => {
        const observer = new MutationObserver(() => {
            let textmobile = document.querySelector('#bottomsheet .sendbird-openchannel-conversation .sendbird-openchannel-footer') as HTMLCollectionOf<HTMLElement>[0]
            let scrollmobile = document.querySelector('#bottomsheet .sendbird-openchannel-conversation-scroll') as HTMLCollectionOf<HTMLElement>[0]

            let textdesktop = document.querySelector('.sendbird-openchannel-conversation .sendbird-openchannel-footer') as HTMLCollectionOf<HTMLElement>[0]
            let scrolldesktop = document.querySelector('.sendbird-openchannel-conversation-scroll') as HTMLCollectionOf<HTMLElement>[0]

            const marqueeText = document.getElementById('banner_text');
            const marqueeContainer = document.getElementById('banner_container');

            if (textdesktop) {
                if (authStatus !== 'authenticated') {
                    textdesktop.classList.add('unauthenticated')
                } else {
                    textdesktop.classList.add('authenticated')
                }
            }

            if (textmobile) {
                if (authStatus !== 'authenticated') {
                    textmobile.classList.add('unauthenticated')
                } else {
                    textmobile.classList.add('authenticated')
                }
            }

            if (marqueeText && marqueeContainer) {
                const textWidth = marqueeText.offsetWidth;
                const containerWidth = marqueeContainer.offsetWidth;

                // Total distance the text needs to travel: its own width + the container width
                const distance = textWidth + containerWidth;
                
                // Set animation duration proportional to the distance to travel
                const duration = (distance / containerWidth) * 10; // Adjust the factor for desired speed

                // Apply the animation dynamically
                marqueeText.style.animation = `marquee ${duration}s linear infinite`;
            }

        });

        // Start observing the target node for configured mutations
        observer.observe(document.body, { childList: true, subtree: true });

        // Clean up the observer on unmount
        return () => observer.disconnect();
    }, [componentRef]);

    //
    useEffect(() => {
        const onStartChat = async () => {
            // Ensure that SDK is initialized
            if (!sdk?.openChannel) {//|| !sdk.groupChannel) {
                if (!sdk) {//|| !sdk.groupChannel) {
                    return;
                }
                return;
            }
            // In production, you should handle the error using try-catch
            setOpenChannelEntered({ isLoading: true })
            try {
                const channel = await sdk.openChannel.getChannel(channelUrl)
                await channel.enter();
                setOpenChannelEntered({ isError: false, isSuccess: true, isInitialized: true, isLoading: false })
            } catch (error: any) {
                console.log(error.toString())
                setOpenChannelEntered({ isError: true, isSuccess: false, isLoading: false, error: error.toString() })
            }
        };
        onStartChat()
    }, [channelUrl, sdk?.openChannel])

    if (openChannelEntered.isError) {
        return (<Alert severity="error">Oops... Error occurred while  initiating a chat.  Please try again later.</Alert>)
    }



    if (isErrorLoggedInUserProfile) {//||  !loggedInUserProfile){
        return (<>{`Oops - Sorry, Chat Error${printError(errorLoggedInUserProfile)} has occurred.`}</>)
    }

    if (openChannelEntered.isLoading || isLoadingLoggedInUserProfile) {//||  !loggedInUserProfile){
        return (<><CircularProgressCentered /></>)
    }
    if (openChannelEntered.isSuccess && isSuccessLoggedInUserProfile) {
        return (
            <Stack direction="column" className='announcement_channel'>
                <Box
                    ref={componentRef}
                    sx={{
                        width: '100%',
                        maxHeight: '525px',
                        overflowY: 'scroll',
                        borderRadius: 6,
                        backgroundColor: '#F8F8F8',
                        overscrollBehavior: 'contain'
                    }}
                    onTouchStart={(e) => e.stopPropagation()} // Add this line
                    onTouchMove={(e) => e.stopPropagation()}  // Add this line
                >
                    {/*//use skeleton here or in OpenChanneltHeaderMM*/}
                    {!isInitialized && <Skeleton></Skeleton>}
                    <OpenChannel channelUrl={channelUrl!} disableUserProfile={true}
                        renderHeader={() => <OpenChanneltHeaderMM setInitialized={setInitialized} />}
                    />
                </Box>
                {authStatus != 'authenticated' &&
                    <>
                        <Button variant="text" onClick={() => { navigate("/SignUp"/*'/sign-in'*/) }} sx={{ fontSize: '11px' }}>
                            create a free account to use messaging
                        </Button>
                    </>
                }
            </Stack>

        )
    }
    return (<></>)
}


export const OpenChanneltHeaderMM: React.FC<{ setInitialized: (isLoading: true) => void }> = ({ setInitialized }) => {
    //const dispatch = useDispatch();
    const context = useOpenChannelContext();
    //const [refreshCount, setRefreshCount] = useState(0)
    //const { allMessages, loading } = context
    //const bannerAnnouncement: CoreMessageType | null = allMessages.length ? allMessages[allMessages.length - 1] : null
    //const messageText = getMessageValue(bannerAnnouncement)
    useEffect(() => {
        if (context.initialized) {
            setInitialized(true)
            //refresh()
        }
    }, [context, context?.loading])

    return (
        <OpenChatHeaderWithBanner >
            <Typography variant='subtitle1' sx={{ fontWeight: 'bold' }}>Public Chat & Announcements </Typography>
        </OpenChatHeaderWithBanner>)

}
interface OpenChatHeaderProps {
    children: React.ReactNode; // ReactNode allows passing JSX, strings, numbers, arrays, etc.
    //isLoading: boolean //getting from context
}

export const OpenChatHeaderWithBanner: React.FC<OpenChatHeaderProps> = ({ children }) => {
    const [openAnnouncement, setOpenAnnouncement] = useState(false)
    const context = useOpenChannelContext();
    const { messageText, messageDate } = GetLatestMessage(context.allMessages)

    return (<Stack direction="row" alignItems={'center'} justifyContent={'center'} gap={1} sx={{ p: 1, backgroundColor: '#F8F8F8' }}>
        <Paper elevation={0} sx={{ width: '100%', borderRadius: 6 }} >
            <Stack direction="row" alignItems={'center'} justifyContent={'center'} gap={1} sx={{ p: 1 }}>
                <CampaignIcon sx={{ color: '#656EFF' }} fontSize='large' />
                <div>{children}</div>
                {context.loading && <Skeleton animation="wave" variant="rectangular" width='100%' height={100} />}
            </Stack>
            <Box id="banner_container">
                <Stack direction="column" sx={{ display: 'flex', alignItems: 'center' }}>
                    <Stack direction="row" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        {messageDate &&
                           <Button variant="outlined" onClick={() => setOpenAnnouncement(true)} sx={{fontSize:'11px', borderRadius:6}} startIcon={<OpenInNewIcon />}>
                           {"Learn More: "+messageDate.toDateString()}
                       </Button>
                        }
                    </Stack>
                    <Typography id="banner_text" variant='subtitle1' sx={{ fontWeight: 'bold' }}>{messageText}</Typography>
                    {messageDate &&
                    <Dialog onClose={() => setOpenAnnouncement(false)} open={openAnnouncement}>
                        <DialogTitle>
                            <Stack direction="row" sx={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                                <CampaignIcon sx={{ color: '#656EFF' }} fontSize='large'/>
                                <Typography variant="h6" sx={{fontWeight:'bold'}}>Announcement</Typography>
                                <IconButton aria-label="delete" onClick={() => setOpenAnnouncement(false)}>
                                    <CloseIcon />
                                </IconButton>
                            </Stack>
                        </DialogTitle>
                        <Paper sx={{p:4}}>
                            <Typography variant='subtitle1' sx={{fontWeight:'bold'}}>{messageDate.toDateString()}</Typography>
                            <Typography variant='subtitle1'>{messageText}</Typography>
                        </Paper>

                    </Dialog>
                    }
                </Stack>
            </Box>

        </Paper>
    </Stack>)
}

function getMessageValue(message: CoreMessageType | null): string | null {
    if (!message)
        return null
    switch (message.messageType) {
        case 'user':
            return null; // Cast to UserMessage and return the message
        case 'admin':
            return (message as AdminMessage).message; // Cast to AdminMessage and return the message
        case 'file':
        //   case 'multiple_files':
        //     // File-based messages don't have a `.message`, return null or file-related info if needed
        //     return null;
        default:
            return null; // If the message type doesn't have text, return null
    }
}

const GetLatestMessage: (allMessages: CoreMessageType[]) => { messageText: string | null, messageDate: Date | null } = (allMessages) => {
    let bannerAnnouncement = null;
    let messageText = null;
    let messageDate = null;

    if (allMessages.length || allMessages.length > 0) {
        for (let i = allMessages.length - 1; i >= 0; i--) {
            bannerAnnouncement = allMessages[i]
            messageText = getMessageValue(bannerAnnouncement)
            if (messageText) {
                messageDate = new Date(bannerAnnouncement.createdAt)
                break;
            }
        }
    }
    return { messageText: messageText, messageDate: messageDate }
}

// to be used outside of the opnechannel provider
export const AnnouncementChannelBanner: React.FC<OpenChatHeaderProps> = ({ children }) => {
    return (
        <Box id="banner_container">
            <OpenChannelProvider channelUrl={GetAnnouncementsChannelUrlForEnvironment().channelUrl}>
                <OpenChatHeaderWithBanner children={children} />
            </OpenChannelProvider>
        </Box>
    )
}
