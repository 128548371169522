import React, { FC, useState, } from "react";
//import { loadData, remove_carecenters } from '../../features/loadDataSlice';
import { Box, Grid, Stack } from '@mui/material';
import Pagination from '@mui/material/Pagination';
import { /*useSelector, useDispatch,*/ shallowEqual } from 'react-redux';
//import { AnyAction, AsyncThunkAction, ThunkDispatch } from "@reduxjs/toolkit";
//import { CareCenterCard, CareCenterInfo } from './kuku';
import { PatientCard } from './PatientCard'


//import { CareCenterCard, CareCenterInfo } from "./CareCenterCard";


export interface PatientsProp {
    patientsData: any[],
    pageCap: number,
    align: string,
    renderPatientCard?: (patientInfo: any, ) => React.ReactNode
}
export const Patients: FC<PatientsProp> = React.memo(({ patientsData, pageCap, align, renderPatientCard }) => {
    const [page, setPage] = useState(0)
    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value - 1);
    };
    const onPageCount = pageCap
    const pageCount = Math.ceil(patientsData.length / onPageCount)
    const renderContent = (patientsData: any[]) => {
        if (patientsData) {
            if (patientsData.length > 0)
                return patientsData.slice(onPageCount * page, onPageCount * page + onPageCount).map((row, index) => {
                    return renderPatientCard ? renderPatientCard( row) : <PatientCard patientInfo={row} align={align} key={row.id} />
                })
        }
        return <></>
    }

    return (
        <Box sx={{ width: '100%' }}>
            {renderContent(patientsData)}
            <Grid item xs={12}  >
                <Stack alignItems="center">
                    <Box sx={{ display: 'flex', justifyContent: "center" }}>
                        <Pagination onChange={handleChange} count={pageCount} color="primary" sx={{ width: '100%', alignContent: 'center', alignItems: 'center', pb: 10 }} />
                    </Box>
                </Stack>
            </Grid>
        </Box>
    )
}, shallowEqual)