import React, { useState, useEffect, useRef, ReactNode } from "react"
//import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
//import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
//import { createTheme, ThemeProvider } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
//import Button from '@mui/material/Button';
//import Stack from '@mui/material/Stack';
import { useAuthProfile } from "../../app/hooks"
//import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import { IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import Tooltip from '@mui/material/Tooltip';
import { useLazyGetSearchResultsQuery, useGetFAQsQuery, useGetRecentSearchesQuery, useLazyGetSearchAutocompleteQuery } from '../../features/resourcesSlice'
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import Markdown from 'react-markdown'

import Tabs from '@mui/material/Tabs';
import Tab, { TabProps } from '@mui/material/Tab';
import { styled } from '@mui/material/styles';
import { Alert, Button, Box, Stack, Skeleton, ListItemButton, ListItem, ListItemText, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import List from '@mui/material/List';
//import ListItem from '@mui/material/ListItem';
//import ListItemButton from '@mui/material/ListItemButton';
//import ListItemIcon from '@mui/material/ListItemIcon';
//import ListItemText from '@mui/material/ListItemText';
import Link from '@mui/material/Link';
import { useSearchParams } from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Snackbar from '@mui/material/Snackbar';
import SmsOutlinedIcon from '@mui/icons-material/SmsOutlined';
//import Badge from '@mui/material/Badge';
//import PersonIcon from '@mui/icons-material/Person';
import { debounce } from '@mui/material/utils';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import { StartChat } from "../markers/MMMarkers";
import { DirectChatUsers, useCreateOrJoinGroupChatMutation, useSetChatUserMutation } from '../../features/ChatSlice'
import { useNavigate, useLocation } from "react-router-dom";
import ArrowCircleRightRoundedIcon from '@mui/icons-material/ArrowCircleRightRounded';
import { MarkdownEmbeddedImageRenderer } from '../../app/blob-url-from-Base64'
import { Padding } from "@mui/icons-material";
import { cleanText } from "./ResourceLanding";
import { RootState } from '../../app/store'; // Import the RootState type
import { RenderQuestionMetaData } from "./RenderStudies";
import Loadash from 'lodash';
import { CircularProgressCentered } from "../../app/sharedcomponents";

const ResourceTab: React.FC<TabProps> = (props) => (
  <Tab
    disableRipple
    sx={{
      textTransform: 'none',
      fontWeight: 'bold',
      borderRadius: 4,
      marginRight: 1,
      marginLeft: 1,
      color: '#777777',
      backgroundColor: '#FAFAFA',
      '&:hover': {
        color: '#40a9ff',
      },
      '&.Mui-selected': {
        color: 'white',
        backgroundColor: '#656EFF',
      },
    }}
    {...props}
  />
);

interface SearchText { cleanText: string, validateText: string }


export const PerplexitySearchResultsWithNavigation: React.FC<{ setLoading: (isLoading: boolean) => void }> = ({ setLoading }) => {
  const { authStatus, profile: profileLoggedIn, isLoading: isLoadingpProfileLoggedIn,    // isLoading: isLoadingProfileLoggedIn, isError: isErrorProfileLoggedIn, error: errorProfileLoggedIn, userAttributesStateStatus 
  } = useAuthProfile()
  const [SetChat, { isLoading: isLoadingSetChat, isSuccess: isSuccessSetChat, isError: isErrorSetChat, data: setChatData, originalArgs }] = useSetChatUserMutation()
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  // Access the current location (which contains the query string)
  const location = useLocation();
  // Use URLSearchParams to parse the query string
  const queryParams = new URLSearchParams(location.search);
  const searchText = queryParams.get('search');
  const cleanTextObject = cleanText(searchText ?? '')
  const category = searchParams.get('category');

  const eligibility = searchParams.get('eligibility')
  const studyType = searchParams.get('studyType')
  const compensation = searchParams.get('compensation')
  const studyContactId = searchParams.get('contactId')
  const status = searchParams.get('status')

  const [showSnackBar, setShowSnackBar] = React.useState<{ show: boolean, severity: 'error' | 'info' | 'success' | 'warning', text: string }>({ show: false, severity: 'success', text: '' });


  useEffect(() => {
    if (isSuccessSetChat) {
      if (!originalArgs?.messageEmail) {
        if (isSuccessSetChat) {
          navigate("/Messages", { state: { chatAndUserData: setChatData } })
        }
      } else {
        setShowSnackBar({ show: true, severity: 'info', text: 'Message Sent' })
      }
    }
    if (isErrorSetChat) {
      setShowSnackBar({ show: true, severity: 'error', text: 'Oops... Sorry, we can not communicate your interest in this study at the moment, please try again later' })
    }

  }, [isSuccessSetChat, originalArgs, isErrorSetChat])//dispatch, navigate,data.id, 



  return (<>
    <Paper sx={{ width: '100%' }}>
      <Stack direction="column" alignItems={"flex-start"} gap={1} sx={{ p: 1 }}>

        <Button startIcon={<ArrowBackIcon />} onClick={() => { navigate(-1) }}>
          Back
        </Button>

        <PerplexitySearchResults searchText={cleanTextObject} setLoading={setLoading}
          renderAbove={(searchText) => <>
            <Stack direction="row" alignItems="center" gap={1} sx={{ display: 'flex', flexShrink: 1 }}>
              <Stack direction="column" gap={0} sx={{ display: 'flex', flexShrink: 1 }}>
                {category !== '3' && <ResourceChatButton searchText={searchText} />}
              </Stack>
              <CopyToClipboardButton text={searchText.cleanText} />
            </Stack>
            {category === '3' && <><Box sx={{ width: '100%', marginTop: '15px' }}><RenderQuestionMetaData metaData={{ eligibility: eligibility, studyType: studyType, compensation: compensation, status: status }} question={cleanTextObject.cleanText} />
            </Box>
              <Box sx={{ width: '100%', marginTop: '15px' }}><Divider></Divider></Box></>}

          </>}
          renderBelow={category === '3' ? (searchText) =>
            <Box sx={{ width: '100%', gap: '10px', flexWrap: 'wrap', alignContent: 'space-between', justifyContent: 'space-around', alignItems: "center", display: 'flex', flexDirection: 'row', flexShrink: 1, marginBottom: '20px' }}>
              <Button disabled={profileLoggedIn.id === Number(studyContactId) || status?.toLowerCase()==='closed'} id="study_im_interested" disableElevation sx={{ alignSelf: 'center', textTransform: 'none', fontWeight: 'normal', borderRadius: 8, backgroundColor: '#656EFF' }} variant="contained"
                onClick={() => {
                  StartChat([profileLoggedIn.id, Number(studyContactId)], authStatus, () => { },
                    (setChatArg: DirectChatUsers) => {
                      SetChat({
                        ...setChatArg, sendInChat: true, sendEmail: true,
                        subject: 'A Patient Connect user is interested in your study',
                        messageEmail: `${profileLoggedIn.name_public} expressed interest in participating in this study:\n${Loadash.startCase(searchText.cleanText)}.`,
                        messageChat: `Hi,\n\nI am interested in participating this study: ${Loadash.startCase(searchText.cleanText)}.`
                      },
                      )
                    })
                }} >
                I'm interested
              </Button>

              <Button disabled={profileLoggedIn.id === Number(studyContactId) || status?.toLowerCase()==='closed'} id="study_chat" disableElevation sx={{ alignSelf: 'center', textTransform: 'none', fontWeight: 'normal', borderRadius: 8, backgroundColor: '#656EFF' }} variant="contained"
                onClick={() => {
                  //SetChat({id: profileLoggedIn.id, messageToId: profile.id})
                  StartChat([profileLoggedIn.id, studyContactId], authStatus, () => navigate("/Messages"),
                    (setChatArg: DirectChatUsers) => {
                      SetChat(setChatArg)
                    })
                }} >
                  <Box sx={{ marginTop: '1px', display: 'flex', flexDirection: 'row', alignItems: 'center',   marginRight: '5px'}}>
                <SmsOutlinedIcon />
                </Box>
                Chat with administrator
              </Button>
            </Box> : undefined}
        />
      </Stack>
      {(isLoadingpProfileLoggedIn || isLoadingSetChat) && <>       <CircularProgressCentered />
        {!isLoadingSetChat && <Skeleton animation="wave" sx={{ mt: '5px' }} variant="rounded" width={200} height={100} />}
      </>}

    </Paper>
    <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} autoHideDuration={6000} open={showSnackBar.show} onClose={() => setShowSnackBar({ ...showSnackBar, show: false })} >
      <Alert onClose={() => setShowSnackBar({ ...showSnackBar, show: false })} severity={showSnackBar.severity ?? "success"} variant="filled" sx={{ width: '100%' }}>
        {showSnackBar.text}
      </Alert>
    </Snackbar>
  </>)

}



//<Stack direction="row" alignItems="center" gap={1} sx={{ display: 'flex', flexShrink: 1 }}>
//            <Stack direction="column" gap={0} sx={{ display: 'flex', flexShrink: 1 }}>
//              <ResourceChatButton searchText={searchText} />
//            </Stack>
//            <CopyToClipboardButton text={searchText.cleanText} />
//          </Stack>


export const PerplexitySearchResults: React.FC<{
  searchText: SearchText,
  //stat
  setLoading: (isLoading: boolean) => void, //must be wrapped in useEffect so not to violate React's rendering rules.
  renderAbove?: (searchText: SearchText) => React.ReactNode,
  renderBelow?: (searchText: SearchText) => React.ReactNode
}> = ({ searchText, setLoading, renderAbove, renderBelow }) => {
  const { authStatus, profile, messagingReady, isLoading: isAuthProfileLoading, isSuccess, isError, error, userAttributesStateStatus } = useAuthProfile()
  const isDataAboutToArrive = useSelector((state: RootState) => state.lazyResouceCompanion.isDataAboutToArrive);
  //const [SetChat, { isLoading: isLoadingSetChat, isSuccess: isSuccessSetChat, isError: isErrorSetChat, data: setChatData, originalArgs //isError: isErrorSetChat, error: errorSetChat, reset 
  //}] = useCreateOrJoinGroupChatMutation()
  //console.log(searchResult?.result )
  const [getSearch, { data: searchResult, isLoading: searchLoading, isFetching: isSearchFetching, isSuccess: isSearchSuccess, isError: searchError, isUninitialized: isSearchUninitialized }] = useLazyGetSearchResultsQuery()
  useEffect(() => {
    if (isSuccess && searchText) {
      getSearch({ query: searchText.cleanText, profile_id: profile.id }, true)// preferCacheValue: false // { forceRefetch: true })
    }
  }, [searchText, isSuccess, profile?.id])

  useEffect(() => {
    setLoading(searchLoading ?? false)//must be wrapped in useEffect so not to violate React's rendering rules.
  }, [searchLoading])

  return (<>
    {isDataAboutToArrive && isSearchSuccess &&
      <>
        {searchResult?.valid && renderAbove && renderAbove(searchText)}
        <MarkedDown searchText={searchText} searchResult={searchResult} />
        {searchResult?.valid && renderBelow && renderBelow(searchText)}
      </>
    }
    {!isDataAboutToArrive &&
      <Stack direction="column" gap={1}>
        <Skeleton animation="wave" variant="rectangular" width='150px' height={30} />
        <Skeleton animation="wave" variant="rectangular" width='200px' height={30} />
        <Skeleton animation="wave" variant="rectangular" width='100%' height={200} />
      </Stack>
    }
    {searchError && <Alert severity="error">Oops... Sorry, an error occurred answering your question.  Please try again later.</Alert>}

  </>)
}


export const ResourceChatButton: React.FC<{ searchText: SearchText }> = ({ searchText }) => {
  const { authStatus, profile, messagingReady, isLoading: isAuthProfileLoading, isSuccess, isError, error, userAttributesStateStatus } = useAuthProfile()
  const [SetChat, { isLoading: isLoadingSetChat, isSuccess: isSuccessSetChat, isError: isErrorSetChat, data: setChatData, originalArgs //isError: isErrorSetChat, error: errorSetChat, reset 
  }] = useCreateOrJoinGroupChatMutation()

  const navigate = useNavigate()
  useEffect(() => {
    if (isSuccessSetChat && setChatData) {//&& messagingReady) {
      // if( !messagingReady)
      //     dispatch(chatApi.util.invalidateTags(['Ready']))
      navigate("/Messages", { state: { chatAndUserData: setChatData } })//navigate("/Messages", { state: { messageTo: profile.iam_id, id: profile.id } })
    }
    // if (isErrorSetChat) {
    //     // Handle the error, and you can access originalArgs here           
    //   }
  }, [isSuccessSetChat, messagingReady, navigate, setChatData])
  return (<>
    <Stack direction="row">
      <LoadingButton sx={{ fontSize: '11px', backgroundColor: '#656EFF' }} id="resource_query_chat" loading={isLoadingSetChat}
        disableElevation variant="contained" startIcon={<PeopleAltIcon />} onClick={() => {
          const ids = [profile.id, 1]
          StartChat(ids, authStatus, () => navigate("/Messages"), () => SetChat({ loggedInId: profile.id, channelType: 'resource-query', channelName: searchText.cleanText ?? '', ids: [profile.id, 1] }), true)
        }}>
        Join discussion
      </LoadingButton>
    </Stack>

    {(isErrorSetChat) && <Alert severity="error">Oops... Error occurred while  initiating a chat.  Please try again later.</Alert>}

  </>)
}




export const MarkedDown: React.FC<{ searchText: SearchText, searchResult: any }> = ({ searchText, searchResult }) => {
  return (
    <>
      <MarkdownEmbeddedImageRenderer markdownContent={searchResult.result} encodedImages={searchResult.encodedImages} />
      {
        searchResult.sources?.length > 0 &&
        <>
          <Typography variant="h6">Additional Sources:</Typography>
          <ul>
            {searchResult.sources.map((row: any) => (
              <li key={row.link}><a href={row.link} target="_blank">{row.title}</a></li>
            ))}
          </ul>
          {searchResult.valid &&
            <Stack direction="row" alignItems="center" gap={1} sx={{ display: 'flex', flexShrink: 1 }}>
              <CopyToClipboardButton text={searchText.cleanText} />
            </Stack>
          }
        </>
      }
    </>)
}

// Clipboard component
export const CopyToClipboardButton: React.FC<{ text: string, getUrlString?: (text: string) => string }> = ({ text, getUrlString }) => {
  const [open, setOpen] = useState(false);

  //const baseURL = window.location.origin + window.location.pathname + "?search=" + encodeURIComponent(text);
  //const baseURL = window.location.origin + "/map/Resources" + "?search=" + encodeURIComponent(text);
  const location = useLocation();

  const handleClick = () => {
    // Get the current location object
    const currentUrl = getUrlString ? getUrlString(text) : `${window.location.href}`// `${location.pathname}${location.search}${location.hash}`;
    navigator.clipboard.writeText(currentUrl)//baseURL.toString());
    setOpen(true);
  };

  return (
    <>
      <Button startIcon={<ContentCopyIcon fontSize="small" />} onClick={handleClick} sx={{ color: '#777777', fontSize: '11px', backgroundColor: '#F4F4F4' }}>
        Share this resource
      </Button>
      <Snackbar
        message="Copied resource link to clipboard"
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        autoHideDuration={2000}
        onClose={() => setOpen(false)}
        open={open}
      />
    </>
  );
};


// const ChatButton : React.FC<profile: any> = ({profile}) =>{
//   return( <LoadingButton sx={{ fontSize: '11px', backgroundColor: '#656EFF' }} loadingPosition="start" id="resource_query_chat" loading={isLoadingSetChat}
//     disableElevation variant="contained" startIcon={<PeopleAltIcon />} onClick={() => {
//       const ids = [profile.id, 1]
//       StartChat(ids, authStatus, () => navigate("/Messages"), () => SetChat({ loggedInId: profile.id, channelType: 'resource-query', channelName: searchText.cleanText ?? '', ids: [profile.id, 1] }), true)
//     }}>
//     Join discussion
//   </LoadingButton>)
// }

