import React, { FC } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { AccordionSummary, AccordionDetails, Accordion, /*LinearProgress,*/ CircularProgress } from '@mui/material';
import { /*useGetProfilesForPointQuery,*/ useGetProfilesPinDataForDefaultPointQuery } from '../../features/profilesSlice';
import { /*useSelector, useDispatch,*/ shallowEqual } from 'react-redux';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { Patients } from './Patients';
import { Box } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
/*
export const PatientSummary: FC<any> = React.memo (({ center }) => {
    const { user, route, authStatus  } = useAuthenticator( (context) => [context.user])
    if (authStatus === 'configuring')
    return null         
    return <PatientSummaryMemoized center={center} user={user}/>

})
*/
export const PatientSummary: FC<any> = React.memo(({ center }) => {
    //const searchState: any = useSelector<any>(state => state.search);
    //const { data: profiles, isLoading, isSuccess, isError, error, } = useGetProfilesForPointQuery(center);
    const { data: profiles, isLoading, isSuccess, isError, error, } = useGetProfilesPinDataForDefaultPointQuery();
    if (isLoading) return null
    if (isError) return (<>Error</>)
    //const textRef = useRef<HTMLDivElement>(null);
    const profilesExist: any[] = profiles ?? [] 

    // see https://github.com/mui/material-ui/issues/35144 for explanation of  TransitionProps={{ unmountOnExit: true, mountOnEnter: true }}
    return isSuccess ? (
        <Accordion 
            disableGutters 
            elevation={0} 
            sx={{ 
                border: 1, 
                borderRadius:8, 
                borderColor: '#E0E0E0', 
                borderStyle: 'solid',
                '&:before': {
                    display: 'none',
                }
            }}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
            >
                {isLoading && <CircularProgress />}
                {isError && <>{`Sorry, ${error === undefined ? "error" : (error as FetchBaseQueryError).status.toString()} has occurred in ReqCardGrid.`}</>}
                {isSuccess &&
                    profilesExist.length > 0 &&
                    <>
                        <Card elevation={0} sx={{ width: '100%', }}>
                            <CardContent >
                                <Typography variant="h6">
                                    {profiles[0].count_in_radius} Member{profiles[0].count_in_radius > 1 ? "s" : ""} near your pin
                                </Typography>
                                <Typography variant="subtitle1">
                                    {profiles[0].count_total} member pins on the map
                                </Typography>
                            </CardContent>
                        </Card>
                    </>
                }

            </AccordionSummary>
            <AccordionDetails>
                {isSuccess && <Patients patientsData={profiles ?? []} pageCap={5} align={"left"} />}
            </AccordionDetails>
        </Accordion>

    ) : null
}, shallowEqual);

