import * as React from 'react';
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import { IconButton, Button, Popper, Box, Popover, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
//import DirectionsIcon from '@mui/icons-material/Directions';
import PushPinIcon from '@mui/icons-material/PushPin';
import HomeIcon from '@mui/icons-material/Home';
import { useSelector, useDispatch } from 'react-redux';
import { /*search,*/ getSearchByTerm, getSearchByCoordinates } from "../../features/searchSlice";


import { useUpdateProfileMutation } from "../../features/profilesSlice";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { AppDispatch } from '../../app/store';
//import { showMapFilters } from '../../features/dialogSlice';
import { MapFilterMenu } from './MapFilterMenu';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import NearMeIcon from '@mui/icons-material/NearMe';
import ThreePIcon from '@mui/icons-material/ThreeP';
import Snackbar from '@mui/material/Snackbar';
import Alert, { AlertColor } from '@mui/material/Alert';
import { skipToken } from '@reduxjs/toolkit/query';
import { aiAnalyticsApi, useGetSearchByStoryQuery } from '../../features/aiAnalytics';
import { StandaloneSearchBox } from '@react-google-maps/api'
import { setPublicPinClicked } from "../../features/dataProcessingSlice"

enum FormMessageTypes {
  NoResults = 'NoResults',
  MaxCharLimit = "MaxCharLimit",
  noSearchType = 'Please choose search type',
  None = 'none'
}
const formMessages = {
  [FormMessageTypes.NoResults]: { text: 'No results found.', severity: 'info' },
  [FormMessageTypes.MaxCharLimit]: { text: 'You have reached the maximum character limit.', severity: 'info' },
  [FormMessageTypes.None]: { text: '', severity: 'info' },
  [FormMessageTypes.noSearchType]: { text: 'Please choose search type', severity: 'info' }
}
export const SearchBar: React.FC<{ id: number, lat: number, lng: number, profileLat: number, profileLng: number, formattedSearchTerm: string, zipCode: string, authStatus: string }> =
  ({ id: loggedProfileId, lat, lng, profileLat, profileLng, formattedSearchTerm, zipCode, authStatus }) => {



    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [showSearchBar, setShowSearchBar] = React.useState(false)
    const [searchType, setSearchType] = React.useState<string>('')
    const [openSearchTypeSelect, setOpenSearchTypeSelect] = React.useState(false)
    const [formMessage, setFormMessage] = React.useState<FormMessageTypes>(FormMessageTypes.None)
    //const [filterVariant, setFilterVariant] = React.useState<| "contained" | "outlined">("outlined")
    const searchState = useSelector((state: any) => state.search);
    const dispatch = useDispatch<any>();

    const [searchTerm, setSearchTerm] = React.useState('')//need another separate state for args in useGetSearchByStoryQuery, so searchInput could be cleared without triggering useGetSearchByStoryQuery
    const [searchInput, setSearchInput] = React.useState('')

    const args = (searchType === 'story' && searchTerm) ? { id: loggedProfileId, story: searchTerm, authStatus: authStatus } : skipToken
    const { data: dataGetSearchByStory, isLoading: isLoadingGetSearchByStory, isSuccess: isSuccessGetSearchByStory, isFetching: isFetchingGetSearchByStory, isUninitialized: isUninitializedGetSearchByStory, isError: isErrorGetSearchByStory } = useGetSearchByStoryQuery(args);

    React.useEffect(() => {
      if (isSuccessGetSearchByStory && !isFetchingGetSearchByStory) {
        if (dataGetSearchByStory?.length === 0) {
          if (formMessage !== FormMessageTypes.NoResults) {
            setFormMessage((current) => FormMessageTypes.NoResults)
          }
        } else {         
          setSearchInput('')
        }
      }
    }, [isSuccessGetSearchByStory, dataGetSearchByStory, isFetchingGetSearchByStory])

    function useIsWidthUp(breakpoint: any) {
      const theme = useTheme();
      return useMediaQuery(theme.breakpoints.up(breakpoint));
    }

    function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
      event.preventDefault();
      const form = event.currentTarget;
      //let searchTerm: string = (document.getElementById('search-bar') as HTMLInputElement).value
      if (searchInput.trim().length == 0) {
        return
      }

      if (searchInput.length > 200) {
        setFormMessage(FormMessageTypes.MaxCharLimit)
        return
      }

      if (!searchType) {
        setFormMessage(FormMessageTypes.noSearchType)
        return
      }

      if (searchType == 'location') {
        dispatch(getSearchByTerm(searchInput.trim()));
        setFormMessage(FormMessageTypes.None)
        setSearchInput('')
      } else if (searchType == 'story') {
        //dispatch(getSearchByStory({id:loggedProfileId, story:searchTerm, authStatus:authStatus}))
        const newSearchTerm = searchInput.trim()
        setSearchTerm(newSearchTerm)      
        setFormMessage(FormMessageTypes.None)
        dispatch(aiAnalyticsApi.util.invalidateTags(['AiStorySearch']));
      }
      dispatch(setPublicPinClicked(false))
      //(form.elements.namedItem('search-bar') as HTMLInputElement).value = ""
    }
    const showPin = (lat !== profileLat || lng !== profileLng) && authStatus === 'authenticated'
    // function handleUpdateProfileClick() {
    //   //if (!user)       return
    //   //dispatch(getSearchByTerm(formData.zipCode ?? ''))
    //   UpdateProfile({ id: loggedProfileId, profileDTO: { lat: lat, lng: lng, formattedSearchTerm: formattedSearchTerm, zipCode } })
    //   //dispatch(getSearchByCoordinates( { lat: lat, lng: lng }))
    // }

    const handleHomeClick = () => {
      //dispatch(getSearchByTerm(zipCode))
      dispatch(getSearchByCoordinates({ lat: profileLat, lng: profileLng }));
    }


    function handleSelectSearchType(type: string) {
      setOpenSearchTypeSelect(false)
      if (type === searchType)
        return
      setSearchTerm('')
      setSearchType(type)
      setFormMessage(FormMessageTypes.None)
      //dispatch(aiAnalyticsApi.util.invalidateTags(['AiStorySearch']));
    }

    const [UpdateProfile, { isLoading, isSuccess, isError, error, data: updatedProfile, reset }] = useUpdateProfileMutation()

    const handleFilterClick: (event: React.MouseEvent<HTMLElement>) => void = (event) => {
      //setFilterVariant(anchorEl ?"contained":"outlined"); 
      setAnchorEl(anchorEl ? null : event.currentTarget)
      //dispatch(showMapFilters(true));
    }

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;
    //<Paper elevation={0} sx={{ display: 'flex', width: '100%', backgroundColor: 'rgba(255, 255, 255)' }}
    return (
      <Stack direction="row" gap={1} sx={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}>
        {showSearchBar
          ?
          <Stack direction="column" gap={1} sx={{ display: 'flex', width: '100%' }}>
            <Paper
              elevation={1}
              sx={{
                display: 'flex',
                flexGrow: 1,
              }}
              component="form"
              onSubmit={handleSubmit}
            >
              {searchType == 'location' &&
                <Paper elevation={0}>
                  <IconButton sx={{ color: '#656EFF' }} onClick={() => { setOpenSearchTypeSelect(true) }}>
                    <NearMeIcon />
                  </IconButton>
                </Paper>
              }
              {searchType == 'story' &&
                <Paper elevation={0}>
                  <IconButton sx={{ color: '#656EFF' }} onClick={() => { setOpenSearchTypeSelect(true) }}>
                    <ThreePIcon />
                  </IconButton>
                </Paper>
              }
              {searchType.length > 0 &&
                <Divider orientation="vertical" />
              }
              <InputBase
                value={searchInput}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {                  
                  if (event.target.value.length > 200) {
                    setFormMessage(FormMessageTypes.MaxCharLimit)
                  } else {
                    setSearchInput(event.target.value)
                    if( formMessage !== FormMessageTypes.None) setFormMessage(FormMessageTypes.None)
                  }
                }}
                onClick={() => { searchType.length == 0 ? setOpenSearchTypeSelect(true) : setOpenSearchTypeSelect(false) }}
                sx={{ ml: 1, flexGrow: 1 }}
                placeholder={searchType == 'location' ? 'Search an address' : searchType == 'story' ? 'Search a patient story' : 'Click to choose search type'}
                inputProps={{ 'aria-label': 'search map' }}
              />
              <Divider orientation="vertical" />
              <IconButton sx={{ color: '#656EFF' }} type="submit" aria-label="search" >
                <SearchIcon />
              </IconButton>
              <Divider orientation="vertical" />
              <IconButton sx={{ color: '#656EFF' }} onClick={() => { setShowSearchBar(false); setOpenSearchTypeSelect(false); setSearchType(''); setFormMessage(FormMessageTypes.None) }}>
                <CloseFullscreenIcon />
              </IconButton>
            </Paper>
            {openSearchTypeSelect &&
              <Paper
                elevation={1}
                sx={{
                  display: 'flex',
                  flexGrow: 1,
                }}
                component="form"
                onSubmit={handleSubmit}
              >
                <List sx={{ width: '100%' }}>
                  <ListItem disablePadding onClick={() => { handleSelectSearchType('location') }}>
                    <ListItemButton>
                      <ListItemIcon>
                        <NearMeIcon sx={{ color: '#656EFF' }} />
                      </ListItemIcon>
                      <ListItemText primary="Search an address" />
                    </ListItemButton>
                  </ListItem>
                  <ListItem disablePadding onClick={() => { handleSelectSearchType('story') }}>
                    <ListItemButton>
                      <ListItemIcon>
                        <ThreePIcon sx={{ color: '#656EFF' }} />
                      </ListItemIcon>
                      <ListItemText primary="Search a patient story" />
                    </ListItemButton>
                  </ListItem>
                </List>
              </Paper>
            }
            <Snackbar
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
              autoHideDuration={3000}
              onClose={() => setFormMessage(FormMessageTypes.None)}
              open={formMessage !== FormMessageTypes.None}
              sx={{ mt: 9 }}
            >
              <Alert
                severity={formMessages[formMessage].severity as AlertColor}
                variant="filled"
                sx={{ width: '100%' }}
              >
                {formMessages[formMessage].text}
              </Alert>
            </Snackbar>
          </Stack>
          :
          <Paper>
            <IconButton onClick={() => { setShowSearchBar(true); setOpenSearchTypeSelect(true) }} sx={{ color: '#656EFF' }} aria-label="search" >
              <SearchIcon />
            </IconButton>
          </Paper>
        }
        <Box>
          <Paper>
            <IconButton sx={{ color: '#656EFF' }} onClick={handleFilterClick} aria-label="Filter" >
              <FilterAltIcon />
            </IconButton>
          </Paper>
          <Popover elevation={3} id={loggedProfileId?.toString()} open={Boolean(anchorEl)} sx={{ zIndex: 200 }} onClose={(event: React.MouseEvent<HTMLElement>) => setAnchorEl(null)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            anchorEl={anchorEl}
          >
            <MapFilterMenu />
          </Popover>
        </Box>
      </Stack>
    );
  }
