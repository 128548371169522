import { Alert, Box, Chip, Link, SvgIcon, Typography } from "@mui/material";
import React, { useEffect } from "react"
import { GetPinByCategoryAndType, PinChip, pinIconStyle } from "../MessageProfile";
import { themePolygon } from "../../app/theme";
import { formatPhoneNumber } from "../../app/utils";
import PhoneIcon from '@mui/icons-material/Phone';
import RoomOutlinedIcon from '@mui/icons-material/RoomOutlined';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import { LoadingButton } from '@mui/lab';
import { StartChat } from "./MMMarkers";
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import { useCreateOrJoinGroupChatMutation } from '../../features/ChatSlice'
import { useAuthProfile } from "../../app/hooks"
import { useNavigate } from "react-router-dom";
import EmailIcon from '@mui/icons-material/Email';

export const InfoWindowDetails: React.FC<any> = ({ data, children }) => {
  //const pinData = GetPinByCategoryAndType("Institution", data.type, { style: pinIconStyle })
  return (<>
    <Stack direction="column" gap={0} sx={{ maxHeight: '275px', maxWidth: '225px', p:1, position:'relative', top:0 }}>
      {data.web_site 
      ?
        <Typography variant="body2" sx={{fontWeight:'bold'}}><a target="_blank" rel="noopener noreferrer" href={data.web_site}>
        {data.name}
      </a></Typography>
      :
        <Typography  variant="body2" sx={{fontWeight:'bold'}}>{data.name}</Typography>
      }
      <Box> <PinChip category="Institution" type={data.type} size="small" /></Box>
      {data.address && <Typography display="flex" variant="caption" color="#656EFF"><RoomOutlinedIcon fontSize="small" color="primary" />{data.address}</Typography>}
      {data.phone && <Typography color="primary" display="flex" variant="caption"><PhoneIcon fontSize="small" color="primary" />{formatPhoneNumber(data.phone)}</Typography>}
      {data.email && <Typography display="flex" variant="caption" color="#656EFF"><EmailIcon fontSize="small" color="primary" /><Link href={`mailto:${data.email}`} underline="always" color="primary">{data.email}</Link></Typography>}
      {data.description && <Typography variant="body2">{data.description}</Typography>}
      <div>{children}</div>
    </Stack>
  </>);
}

export const CoeInfoWindowDetails: React.FC<any> = ({ data }) => {
  const navigate = useNavigate()
  const [SetChat, { isLoading: isLoadingSetChat, isSuccess: isSuccessSetChat, isError: isErrorSetChat, data: setChatData, originalArgs }] = useCreateOrJoinGroupChatMutation()
  const { authStatus, profile, messagingReady, isLoading: isAuthProfileLoading, isSuccess, isError, error, userAttributesStateStatus } = useAuthProfile()

  useEffect(() => {
    if (isSuccessSetChat && setChatData) {
      navigate("/Messages", { state: { chatAndUserData: setChatData } })//navigate("/Messages", { state: { messageTo: profile.iam_id, id: profile.id } })
    }
  }, [isSuccessSetChat, messagingReady, navigate, setChatData])
  
  if (isErrorSetChat)
    return <Alert severity="error">Oops... Error occurred while initiating a chat.  Please try again later.</Alert>
  return (<InfoWindowDetails data={data}>
    <>
      {data.mm_program_overview?.href && <Typography variant="body2"><Link target="_blank" rel="noopener noreferrer" href={data.mm_program_overview.href}>{data.mm_program_overview.text??"MM Program Overview"}</Link></Typography>}
      {data.meet_the_mm_team?.href && <Typography variant="body2"><Link target="_blank" rel="noopener noreferrer" href={data.meet_the_mm_team.href}>{data.meet_the_mm_team.text??"MM Specialists"}</Link></Typography>}
      {data.academic_affiliation?.href && <Typography variant="body2">Academic Affiliation:&nbsp;<Link target="_blank" rel="noopener noreferrer" href={data.academic_affiliation.href}>{data.academic_affiliation.text}</Link></Typography>}
      {data.comment?.href &&  <Typography variant="body2"><Link target="_blank" rel="noopener noreferrer" href={data.comment.href}>{data.comment.text??"Addl info"}</Link></Typography>}      
      
      {data.clinical_trials?.href && <Typography variant="body2"><Link target="_blank" rel="noopener noreferrer" href={data.clinical_trials.href}>Clinical Trials</Link></Typography>}            
      {data.myeloma_magizine?.href && <Typography variant="body2"><Link target="_blank" rel="noopener noreferrer" href={data.myeloma_magizine}>Myeloma Magizine</Link></Typography>}
      {data.research?.href && <Typography variant="body2"><Link target="_blank" rel="noopener noreferrer" href={data.research}>Research</Link></Typography>}
      {data.locations?.href && <Typography variant="body2"><Link target="_blank" rel="noopener noreferrer" href={data.locations}>Locations</Link></Typography>}
      {data.facebook?.href && <Typography variant="body2"><Link target="_blank" rel="noopener noreferrer" href={data.facebook}>Facebook</Link></Typography>}
      {data.twitter?.href && <Typography variant="body2"><Link target="_blank" rel="noopener noreferrer" href={data.twitter}>Twitter</Link></Typography>}
      {data.number_of_mm_specialists_simplified && <Typography variant="body2">Number of MM Specialists: {data.number_of_mm_specialists_simplified} </Typography>}
      <Divider sx={{width:'100%', mb:1, mt:1}}></Divider>
      <Stack direction="row">
        <LoadingButton sx={{ fontSize: '11px', backgroundColor: '#656EFF' }} id="resource_query_chat" loading={isLoadingSetChat}
          disableElevation variant="contained" startIcon={<PeopleAltIcon />} onClick={() => {
            const ids = [profile.id, 1]
            StartChat(ids, authStatus, () => navigate("/Messages"), () => SetChat({ loggedInId: profile.id, channelType: 'resource-query', channelName: data.name ?? '', ids: [profile.id, 1] }), true)
          }}>
          Join Group Chat
        </LoadingButton>
      </Stack>
    </>
  </InfoWindowDetails>);
}

export const HousingInforWindowDetails: React.FC<any> = ({ data }) => {
  return (<InfoWindowDetails data={data}>
    <>
    {data.description && <Typography variant="body2">Number of MM Specialists{data.description} </Typography>}
    </>
  </InfoWindowDetails>)

}

export const SupportGroupInfoWindowDetails: React.FC<any> = ({ data }) => {
  const navigate = useNavigate()
  const [SetChat, { isLoading: isLoadingSetChat, isSuccess: isSuccessSetChat, isError: isErrorSetChat, data: setChatData, originalArgs }] = useCreateOrJoinGroupChatMutation()
  const { authStatus, profile, messagingReady, isLoading: isAuthProfileLoading, isSuccess, isError, error, userAttributesStateStatus } = useAuthProfile()
 
  useEffect(() => {
    if (isSuccessSetChat && setChatData) {
      navigate("/Messages", { state: { chatAndUserData: setChatData } })//navigate("/Messages", { state: { messageTo: profile.iam_id, id: profile.id } })
    }
  }, [isSuccessSetChat, messagingReady, navigate, setChatData])

  return (<InfoWindowDetails data={data}>
    <>     
      <Divider sx={{width:'100%', mb:1, mt:1}}></Divider>
      <Stack direction="row">
        <LoadingButton sx={{ fontSize: '11px', backgroundColor: '#656EFF' }} id="resource_query_chat" loading={isLoadingSetChat}
          disableElevation variant="contained" startIcon={<PeopleAltIcon />} onClick={() => {
            const ids = [profile.id, 1]
            StartChat(ids, authStatus, () => navigate("/Messages"), () => SetChat({ loggedInId: profile.id, channelType: 'resource-query', channelName: data.name ?? '', ids: [profile.id, 1] }), true)
          }}>
          Join Group Chat
        </LoadingButton>
      </Stack>
    </>
  </InfoWindowDetails>);
}