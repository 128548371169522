import React, { useEffect } from 'react';
import ReactDOM from "react-dom/client";
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './app/store';
import reportWebVitals from './reportWebVitals';
//import './index.css';

import { App } from './App';
import { SignUp } from './components/iam/Signup';
import { SignIn } from './components/iam/Signin';
import { Contact } from './components/Disclaimer';
import { SignInEmail } from './components/iam/SigninEmail';

import { ForgotPassword } from './components/iam//ForgotPassword';
//import { ForgotPasswordConfirmation } from './components/iam/ForgotPasswordConfirmation'
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { themePolygon } from './app/theme'
import { LinkGrid } from "./components/resources/LinkGrid";
import { ResourceHome } from "./components/resources/ResourceHome";
import { ResourceLanding, PerplexitySearchResultsWithNavigation } from "./components/resources/ResourceLanding";
import { createBrowserRouter, RouterProvider, Navigate } from "react-router-dom";
import { Amplify } from 'aws-amplify';
import awsconfig from './aws-exports';
import { listenForAuthEvents } from './Auth'
import Callback from './app/callback';
import { RouteErrorPage, ErrorBoundary } from './features/ErrorBoundary'
import { ChatMain } from './components/messaging/ChatMain';
import { OpenChannelMain } from './components/messaging/OpenChannelMain'
import { PostHogProvider } from 'posthog-js/react'
import { Authenticator } from "@aws-amplify/ui-react";
import Loadash from 'lodash';
export const capitalizeWords = (input: string): string => {
  return Loadash.words(input).map(Loadash.capitalize).join(' ');
};


//console.log("AWS config:", awsconfig) 
Amplify.configure(awsconfig);
listenForAuthEvents();

const container = document.getElementById('root')!;
const root = ReactDOM.createRoot(container);
export const baseName = window.location.pathname.split("/")[2];
export interface Condition { condition: string, basename: string }
export const coonditionContext = React.createContext<Condition>({ condition: "Multiple Myeloma", basename:'' });

const router = createBrowserRouter([
  {
    path: "/callback",
    element: <App callback={true} />
  },
  {
    path: "/out",
    element: <App out={true} />
  },
  {
    path: "/",
    element: <App />,
    //errorElement: <RouteErrorPage />
  },
  {
    path: "/DataDeletionInstructions",
    element: <App dataDeletionInstructions={true} />
  },
  {
    path: "/Delete-Profile",
    element: <App deleteProfile={true} />
  },
  {
    path: "/ResourceGrid",
    element: <App childComponent={<LinkGrid />} />
  },

  {
    path: "/Messages",
    element: <App messages={true} />
  },
  {
    path: "/Resources",
    element: <App childComponent={<ResourceLanding />} />
  },
  // {
  //   path: "/ResourceResult",
  //   element: <App childComponent={<PerplexitySearchResultsWithNavigation/>} />
  // },
  {
    path: "/KnowledgeConnect",
    element: <Navigate to="/Resources" replace />,
  },
  {
    path: "/Sign-In",
    element: <App childComponent={<SignIn />} />,
  },
  {
    path: "/SignInEmail",
    element: <App childComponent={<SignInEmail />} />,
  },
  {
    path: "/SignInToDeleteProfile",
    element: <App deleteProfile={true} />,
  },
  {
    path: "/SignUp",
    element: <App childComponent={<SignUp />} />,
  },
  {
    path: "/ForgotPassword",
    element: <App childComponent={<ForgotPassword />} />,
  },
  {
    path: "/Profile",
    element: <App />
  },
  {
    path: "/Contact",
    element: <App childComponent={<Contact />} />
  },

], { basename: `/map/${baseName??''}` })//"/map" });
//console.log("rendering Index")
// const defaultTheme = createTheme({
//   typography: {
//     h7: {
//       fontSize: '1.2rem', // Adjust the font size as needed
//       fontWeight: 'bold', // Adjust the font weight as needed
//       // Add any other styles you want for h7 headings
//     },
//   },
// });

root.render(
  //<React.StrictMode>
  <ErrorBoundary>
    <CssBaseline />
    <coonditionContext.Provider value={{ condition: baseName?capitalizeWords(baseName.replaceAll("-", " ")): 'Multiple Myeloma', basename: baseName }}>
      <PostHogProvider
        apiKey={'phc_dcQPE2uIIkdFRFuI4gUpEG3D7T6SKZgxZmJBUNcYlzd'}
        options={{
          api_host: "https://us.i.posthog.com",
        }}
      >
        <Authenticator.Provider>
          <Provider store={store}>
            <ThemeProvider theme={themePolygon}>
              <RouterProvider router={router} />
            </ThemeProvider >
          </Provider>
        </Authenticator.Provider>
      </PostHogProvider>
    </coonditionContext.Provider>
  </ErrorBoundary>
  //</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
