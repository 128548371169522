import React from "react";
import { SearchBar } from "./SearchBar";
import { MapFilter } from "./MapFilter";
import {  Stack, Box } from "@mui/material";
import { MapFilterProps } from './MapFilter'

export const MapBars: React.FC<MapFilterProps> = (props) => {
  return (<>
  <Box
    sx={{
      position: {
        xs: 'absolute', // theme.breakpoints.up('xs')
        sm: 'absolute', // theme.breakpoints.up('sm')
        md: 'fixed', // theme.breakpoints.up('md')
      },
      top: {
        xs: '20px', // theme.breakpoints.up('xs')
        sm: '20px', // theme.breakpoints.up('sm')
        md: '80px', // theme.breakpoints.up('md')
      },
      right: '10px',
      width: {
        xs: '95%', // theme.breakpoints.up('xs')
        sm: '95%', // theme.breakpoints.up('sm')
        md: '55%', // theme.breakpoints.up('md')
        lg: '65%',
      },     
      zIndex: 1    
    }}>
    
      <Stack sx={{width: '100%' }} gap={1}  >      
        <SearchBar  {...props}/>  
        <Box  sx={{  
          display: {   
            xs: 'none', // theme.breakpoints.up('xs')
            sm: 'none', // theme.breakpoints.up('sm')
            md: 'none', // theme.breakpoints.up('md')
            lg: 'none',
            xl: 'block'
          }}}
        >
              
        </Box>    
      </Stack>
    
    </Box>
  </>)

}