// apiSlice.ts
import { createApi } from '@reduxjs/toolkit/query/react'
import { qryFuncWithSubHeader } from './ChatSlice'

export interface GenerateAISummary { id: number, story: string, doAnalytics?: boolean }
export const aiAnalyticsApi = createApi({
    reducerPath: 'aiAnalytics',
    //baseQuery: fetchBaseQuery(),
    baseQuery: qryFuncWithSubHeader,
    tagTypes: ['AiProfile', 'AiStorySearch'],
    endpoints: (builder) => ({
        //gest profile  by iam_is inserts if no matching profile found        
        AIProfileSummary: builder.query<any, number>({
            query: (id) => {
                const qry = `/api/ai/AIProfileSummary/${id}`
                return qry
            },
            providesTags: (result, response, idRetrieved) => {
                return [{ type: 'AiProfile', id: idRetrieved }]
            }
        }),

        generateAISummary: builder.mutation<any, GenerateAISummary>({
            query: ({ id, story, doAnalytics }) => ({
                url: `/api/ai/generateAISummary/`,
                method: 'POST',
                body: { id: id, story: story, doAnalytics: doAnalytics ? true : false }
            }),
            invalidatesTags: (result, response, updated) => [{
                type: 'AiProfile', id: updated.id
            }]
        }),

        getSearchByStory: builder.query<any, { id: number, authStatus: string, story: string }>({// = createAsyncThunk("search/getSearchByStory", async (data, thunkAPI) => {
            query: ({ id, authStatus, story }) => {
                const qry = `/api/ai/searchbystory?id=${id}&authStatus=${authStatus}&story=${story}`
                return qry
            },
            providesTags: (result, response, ) => {
                return [ 'AiStorySearch']
            }
        }),

    }),
})


export const { useAIProfileSummaryQuery, useGenerateAISummaryMutation, useGetSearchByStoryQuery } = aiAnalyticsApi

//export const createQueryArgs = (id, otherProp) => ({ id, otherProp });


