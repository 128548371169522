import React, { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';


export const createImageFromBase64 = (base64Data: string, mimeType: string): string => {
  // Convert base64 data to binary data
  const byteCharacters = atob(base64Data); // Decode base64
  const byteArrays: Uint8Array[] = [];

  // Split the binary data into chunks and convert to Uint8Array
  for (let offset = 0; offset < byteCharacters.length; offset += 512) {
    const slice = byteCharacters.slice(offset, offset + 512);
    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    byteArrays.push(new Uint8Array(byteNumbers));
  }

  // Create a Blob from the binary data
  const blob = new Blob(byteArrays, { type: mimeType });

  // Create and return an object URL for the Blob
  return URL.createObjectURL(blob);
};


export const MarkdownEmbeddedImageRenderer: React.FC<{ markdownContent: string, encodedImages?: any[] }> = ({ markdownContent, encodedImages }) => {
  const [processedMarkdown, setProcessedMarkdown] = useState<string>(markdownContent);

  useEffect(() => {
    const replaceBase64Images = (markdown: string, encodedImages: any[]): string => {      
      const base64Regex = /https:\/\/polygonhealth.com\/image[0-9]/g;
      let modifiedMarkdown = markdown;
      encodedImages.forEach((encodedImage, index) => {
        const { base64Data,  imageUrlPlaceHolder, altText, mimeType} = encodedImage;
        // Create a Blob URL from the base64 image data
        const imageUrl = createImageFromBase64(base64Data, `image/${mimeType}`);
        const imageUrlNoBlob = imageUrl.replace("blob:", '')       
        modifiedMarkdown = modifiedMarkdown.replace( imageUrlPlaceHolder, imageUrlNoBlob);//`![${altText}](${imageUrlNoBlob})`);
      });

      return modifiedMarkdown;
    };
    if (encodedImages) {
      // Process the markdown to replace base64 images with Blob URLs
      const updatedMarkdown = replaceBase64Images(markdownContent, encodedImages);
      setProcessedMarkdown(updatedMarkdown)
    } else {
      setProcessedMarkdown(markdownContent)
    }
  }, [markdownContent, encodedImages]);



  return (
    <div>
      <ReactMarkdown components={{
        img: ({ node, ...props }) => <ImageRenderer src={props.src ?? ''} />,
      }}>{processedMarkdown}</ReactMarkdown>
    </div>
  );
};


const ImageRenderer: React.FC<{ src: string }> = ({ src }) => {
  // Process the image dynamically and replace the base64 with the created URL
  const [imageUrl, setImageUrl] = useState<string>(src);

  // useEffect(() => {
  //   // Example logic to replace base64 with a dynamically generated image URL (in your case, create an actual file or URL)
  //   const base64Regex = /^data:image\/([a-zA-Z0-9]+);base64,(.*)$/;
  //   const match = src.match(base64Regex);

  //   if (match) {
  //     const [, mimeType, base64Data] = match;
  //     //const tempUrl = createTemporaryImageUrl(base64Data, mimeType);
  //     //setImageUrl(tempUrl); // Update state to use the new URL
  //   }
  // }, [src]);
  if (src) {
    return <img src={`blob:${imageUrl}`} alt="dynamic image" width={"100%"} />;
  }
  return null
};
export default MarkdownEmbeddedImageRenderer;
