import React, {useState} from "react"
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export const ForgotPasswordConfirmation: React.FC<any> = (props) => {

  var open = props.open;

  return (
    <React.Fragment>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Password Reset Success"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Your password has been reset successfully.
          </DialogContentText>
          <br></br>
          <DialogContentText id="alert-dialog-description">
            Please go back to Login
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button href="/map/SignInEmail" autoFocus>
            Login
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}