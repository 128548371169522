// resources.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createApi, fetchBaseQuery, BaseQueryFn, FetchArgs, FetchBaseQueryError } from '@reduxjs/toolkit/query/react';

export const resourcesApi = createApi({
  reducerPath: 'resources',
  baseQuery: fetchBaseQuery(),
  tagTypes: ['resources', 'faq'],
  endpoints: (builder) => ({
    getFAQs: builder.query<any, void>({
      query: () => {
        const res = `/api/get/faqs`
        return res
      },
    }),
    getRecentSearches: builder.query<any, void>({
      query: () => {
        const res = `/api/get/recentsearches`
        return res
      },
    }),
    getMiniSearch: builder.query<any, void>({
      query: () => {
        const res = `/api/get/minisearch`
        return res
      },
    }),
    getSearchAutocomplete: builder.query<any, any>({
      query: (query) => {
        const res = `/api/get/searchautocomplete?query=${query}`
        return res
      },
    }),
    getSearchResults: builder.query<any, any>({
      queryFn: () =>{                 
        return { data: {} }
      },
      keepUnusedDataFor: 0,
      async onCacheEntryAdded({ query, profile_id }, { updateCachedData, cacheDataLoaded, cacheEntryRemoved, dispatch }) {
        const eventSource = new EventSource(`/api/get/resources?query=${encodeURIComponent(query)}&profile_id=${encodeURIComponent(profile_id)}`);
        const accumulatedData: any[] = [];
        // prevents infinite triggering of stream
        eventSource.onerror = (err) => {
          eventSource.close();
        };

        try {
          dispatch({ type: 'lazyResouceCompanion/setIsDataAboutToArrive', payload: false });
          updateCachedData((draft) => {
            // Remove all existing properties from draft
            for (const key in draft) {
              delete draft[key];
            }
            //set a state to indicate the data is about to arrive           
          });
          await cacheDataLoaded;
         
          const listener = (event: MessageEvent) => {
            dispatch({ type: 'lazyResouceCompanion/setIsDataAboutToArrive', payload: true });
            const data = JSON.parse(event.data) as any;

            accumulatedData.push(data);

            updateCachedData((draft) => {
              // Remove all existing properties from draft
              for (const key in draft) {
                delete draft[key];
              }
              // Copy all properties from data into draft
              Object.assign(draft, data);
            });
          };

          eventSource.addEventListener('message', listener);

          await cacheEntryRemoved;

          eventSource.removeEventListener('message', listener);
          eventSource.close();
        } catch {
          // Handle errors if needed
          eventSource.close();
        }

      },
    }),
    getLatestNews: builder.query<any, { query: string, profile_id: number }>({
      queryFn: () => ({ data: {} }),
      keepUnusedDataFor: 0,
      async onCacheEntryAdded(args, { updateCachedData, cacheDataLoaded, cacheEntryRemoved }) {

        const eventSource = new EventSource(`/api/get/latestnews?query=${encodeURIComponent(args.query)}&profile_id=${encodeURIComponent(args.profile_id)}`);
        const accumulatedData: any[] = [];
        // prevents infinite triggering of stream
        eventSource.onerror = (err) => {
          eventSource.close();
        };

        try {
          await cacheDataLoaded;

          const listener = (event: MessageEvent) => {
            const data = JSON.parse(event.data) as any;

            accumulatedData.push(data);

            updateCachedData((draft) => {
              // Remove all existing properties from draft
              for (const key in draft) {
                delete draft[key];
              }
              // Copy all properties from data into draft
              Object.assign(draft, data);
            });
          };

          eventSource.addEventListener('message', listener);

          await cacheEntryRemoved;

          eventSource.removeEventListener('message', listener);
          eventSource.close();
        } catch {
          // Handle errors if needed
          eventSource.close();
        }

      },
    }),

  }),
})


export const { useGetMiniSearchQuery, useLazyGetSearchResultsQuery, useGetFAQsQuery, useGetRecentSearchesQuery, useLazyGetSearchAutocompleteQuery, useLazyGetLatestNewsQuery } = resourcesApi

// Define the state shape
interface ApiState {
  isDataAboutToArrive: boolean;
}

// Define the initial state
const initialState: ApiState = {
  isDataAboutToArrive: false,
};

// Create a slice to handle custom API-related state
const lazyResouceCompanionSlice = createSlice({
  name: 'lazyResouceCompanion',
  initialState,
  reducers: {
    setIsDataAboutToArrive(state, action: PayloadAction<boolean>) {
      state.isDataAboutToArrive = action.payload;
    },
  },
});

// Export the action so it can be dispatched
export const { setIsDataAboutToArrive } = lazyResouceCompanionSlice.actions;
export const  lazyResouceCompanionReducer = lazyResouceCompanionSlice.reducer;


