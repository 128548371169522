import React, { useState, useEffect, useRef } from "react"
import Paper from '@mui/material/Paper';
import { Box, Stack, Typography } from '@mui/material';
import { useNavigate } from "react-router-dom";
import Grid from '@mui/material/Grid';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import { showProfile } from "../../features/dialogSlice";
import { useDispatch } from 'react-redux';
import { useAuthProfile } from "../../app/hooks"
import Snackbar from '@mui/material/Snackbar';
import ChatIcon from '@mui/icons-material/Chat';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import DescriptionIcon from '@mui/icons-material/Description';
import IconButton from '@mui/material/IconButton';
import { coonditionContext, Condition, capitalizeWords } from '../../index'

export const NavMini: React.FC = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch<any>();
    const { authStatus, profile: loggedInProfile, isLoading: isAuthProfileLoading, isSuccess, isError, error, userAttributesStateStatus, messagingReady } = useAuthProfile()
    const [open, setOpen] = useState(false);

    function handleProfileClick() {
        if (authStatus == 'authenticated') {
            dispatch(showProfile({ isForced: true }))
        } else {
            navigate('/sign-in')
        }
    }

    const {condition} = React.useContext<Condition>(coonditionContext);
    return (
        <Grid>
            <Grid item xs={12} sx={{pb:1}}>
                <Paper onClick={() => {navigate('/messages')}} elevation={0} sx={{width:'100%', borderRadius:8, cursor:'pointer'}}>
                    <Stack direction="column" sx={{pt:1, pb:1, pl:3,pr:2, display:'flex', width:'100%', backgroundColor:'#FCFCFC', borderRadius:8, border:1, borderColor:'#E0E0E0'}}>
                        <Stack direction="row" gap={2} sx={{display:'flex', alignItems:'center'}}>
                            <IconButton sx={{backgroundColor:'#ECE9F8', borderRadius:12}}><ChatIcon sx={{ color:'#62648F'}} /></IconButton>
                            <Stack direction="column" sx={{width:'100%'}}>
                                <Stack direction="row" gap={1} sx={{display:'flex', justifyContent:'space-between'}}>
                                    <Typography variant="body2" sx={{fontWeight:'bold'}}>Messages</Typography>
                                    <ArrowOutwardIcon sx={{color:'#AAAAAA'}}/>
                                </Stack>
                                <Typography variant="caption" sx={{color:'#777777'}}>Chat with other users</Typography>
                            </Stack>
                        </Stack>
                    </Stack>
                </Paper>
            </Grid>
            <Grid item xs={12} sx={{pb:1}} >
                <Paper onClick={() => {navigate('/resources')}} elevation={0} sx={{width:'100%', borderRadius:8, cursor:'pointer'}}>
                    <Stack direction="column" sx={{pt:1, pb:1, pl:3,pr:2, display:'flex', width:'100%', backgroundColor:'#FCFCFC', borderRadius:8, border:1, borderColor:'#E0E0E0'}}>
                        <Stack direction="row" gap={2} sx={{display:'flex', alignItems:'center'}}>
                        <IconButton sx={{backgroundColor:'#ECE9F8', borderRadius:12}}><FindInPageIcon sx={{ color:'#62648F'}} /></IconButton>
                            <Stack direction="column" sx={{width:'100%'}}>
                                <Stack direction="row" gap={1} sx={{display:'flex', justifyContent:'space-between'}}>
                                    <Typography variant="body2" sx={{fontWeight:'bold'}}>Knowledge Connect</Typography>
                                    <ArrowOutwardIcon sx={{color:'#AAAAAA'}} />
                                </Stack>
                                <Typography variant="caption" sx={{color:'#777777'}}>Search anything about{condition} </Typography>
                            </Stack>
                        </Stack>
                    </Stack>
                </Paper>
            </Grid>
            <Grid item xs={12} sx={{pb:1}}>
                <Paper onClick={() => {window.open("https://www.polygonhealth.com/blog", '_blank')}} elevation={0} sx={{width:'100%', borderRadius:8, cursor:'pointer'}}>
                    <Stack direction="column" sx={{pt:1, pb:1, pl:3,pr:2, display:'flex', width:'100%', backgroundColor:'#FCFCFC', borderRadius:8, border:1, borderColor:'#E0E0E0'}}>
                        <Stack direction="row" gap={2} sx={{display:'flex', alignItems:'center'}}>
                            <IconButton sx={{backgroundColor:'#ECE9F8', borderRadius:12}}><DescriptionIcon sx={{ color:'#62648F'}} /></IconButton>
                            <Stack direction="column" sx={{width:'100%'}}>
                                <Stack direction="row" gap={1} sx={{display:'flex', justifyContent:'space-between'}}>
                                    <Typography variant="body2" sx={{fontWeight:'bold'}}>Blog</Typography>
                                    <ArrowOutwardIcon sx={{color:'#AAAAAA'}}/>
                                </Stack>
                                <Typography variant="caption" sx={{color:'#777777'}}>Read our recent blogs and updates</Typography>
                            </Stack>
                        </Stack>
                    </Stack>
                </Paper>
            </Grid>
        </Grid>
    );
}
//{showDeleteAccountState && <ConfirmDeleteProfile handleSignOut={handleSignOut} />}
//export default ChatsPageLoader;