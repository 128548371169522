import React /*, { ReactNode }*/ from 'react';
import { Box,      IconButton,      Stack, Typography, Container } from '@mui/material';
import { useDispatch } from 'react-redux';
//import { ReactComponent as AISVG } from '../../assets/ai_star.svg';
import {  setAnalyticUsersClicked } from "../../features/dataProcessingSlice"
//import { showProfile } from '../../features/dialogSlice';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { Patients } from '../patient/Patients';

interface AnalyticUsersProps{
    aiSummaryRecord: any
}


export const AnalyticUsers: React.FC<AnalyticUsersProps> = ({aiSummaryRecord}) => {
    const dispatch = useDispatch<any>();
    function closeUserList() {
        dispatch(setAnalyticUsersClicked(false))
        //dispatch(getUsersFromAnalyticsClicked([]))
    }
    const analyticUsers = aiSummaryRecord.type === "numeric" ? aiSummaryRecord.analytics.users : (aiSummaryRecord.type === "text" ? aiSummaryRecord.analytics : undefined)
    if( !analyticUsers)
        return null


    //return (<Stack sx={{ width: '100%', backgroundColor: 'black' }} alignItems="center" direction="column"/>)


    //return (<Box sx={{width: '100%', flexGrow:5, backgroundColor: 'yellow'}}>trecfghtyhrv</Box>)
    return (
        <Box 
            onTouchStart={(e) => e.stopPropagation()} // Add this line
            sx={{ display: 'flex', 
                overflow: 'auto', 
                width: '100%', 
                //height: { xs: '80svh', sm: '80svh', md: '90vh' },
            }}
        >
            <Container>
                <Stack sx={{ width: '100%' , flexGrow: 6}} alignItems="center" direction="column">
                    <Stack alignItems="center" direction="row" sx={{ mt: 4, mb: 2 }}>
                        <IconButton onClick={closeUserList} sx={{ marginRight: 'auto' }}>
                            <KeyboardBackspaceIcon />
                        </IconButton>
                        <Typography variant="h6">
                            {aiSummaryRecord.formattedVariable}
                        </Typography>
                    </Stack>
                    <Patients patientsData={analyticUsers} pageCap={10} align={"center"} />
                </Stack>
            </Container>
        </Box>
    )
}


