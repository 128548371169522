import React, { useState, useEffect, useRef } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { Typography, TextField, Box, Button, AutocompleteChangeReason, AutocompleteChangeDetails, RadioGroup, FormControlLabel, Radio, Chip, Paper } from '@mui/material';
import { StepProps, useStep, ProfileFormData, GetTitleFromTitleSubtitle, GetSubTitleFromTitleSubtitle, GetSubTitle2FromTitleSubtitle, yesNoErrorMessage } from '../wizard/QuestionsForm'
import { CountrySelect } from '../../app/components/CountrySelect';
import { CountryType, USCountryType, GetCountryTypeByLabelDefualtToUs, GetCountryTypeByLabel } from '../../app/components/CountryCodes'
// import { ReactComponent as CaregiverSVG } from '../../assets/caregiver.svg';
// import { ReactComponent as MMWarriorSVG } from '../../assets/patienticon.svg';
// import { ReactComponent as MedicSVG } from '../../assets/medic.svg';
//import { GetPinName } from '../map/MapFilter';
import { GetUserTypePin } from '../MessageProfile';
//import postalCodes from 'postal-codes-js';
import { LocationsMultipleAutocomplete, PlaceType } from '../../app/components/LocationsMultipleAutocomplete';
import { LocationsAutocomplete } from '../../app/components/LocationsAutocomlete';
//const name = "location"//"zipCode"
import { coonditionContext, Condition } from '../../index'



export const Location: React.FC = () => {
  const placeValue: PlaceType[] = []// [{description: 'string',   place_id: 'string',    structured_formatting:{ main_text: 'string',  secondary_text: 'string;'}}]
  const { control, formState: { errors }, formState: { dirtyFields }, trigger, clearErrors, setValue, reset, getValues: kuku, setFocus, watch } = useFormContext<ProfileFormData>();
  const formData = kuku();
  const { step, data, updatedData } = useStep()
  var formOverProfile = { ...data, ...updatedData.data, ...updatedData.profileDTO ?? {} }
  //const defaultedCountryValue = GetCountryTypeByLabel(formData.location?.country ?? data.location?.country ?? '') 
  const defaultedCountryValue = GetCountryTypeByLabelDefualtToUs(formData.location?.country ?? data.location?.country ?? '')
  const [countryObject, setCountryObject] = useState<CountryType | undefined | null>(defaultedCountryValue); // Set default country object
  //const [place, setPlace] = useState<PlaceType[]>  (formData.location?.zipCode ? [{ description: formData.location.zipCode }] : data.zipcode ? [{ description: data.zipcode }] : []);//([])
  const [place, setPlace] = useState<PlaceType | undefined>(formData.location?.zipCode ? { description: formData.location.zipCode } : data.zipcode ? { description: data.zipcode } : undefined);//([])
  const locationAutocompleteRef = useRef<any>(null); // Use a ref to access LocationsAutocomplete
  const typeRef = useRef<any>(null);

  const iconStyle = { width: '20px', height: '20px' }; // Adjust the size as needed


  // useEffect(() => {
  //   if (formData?.location) {
  //     const countryVal = GetCountryTypeByLabelDefualtToUs(formData.location?.country ?? '')
  //     setCountryObject(countryVal)
  //     setPlace(formData.location?.zipCode ? { description: formData.location.zipCode } : data.zipcode ? { description: data.zipcode } : undefined);//(data.zipcode ? [{ description: data.zipcode }] : []);
  //     if( formData.location?.zipCode ){
  //       reset()
  //     }

  //   }
  // }, [formData?.location])

  const validatePostalCode = (value: string) => {
    if (!value) return 'Please choose or enter your location';
  };

  const postalCodeRules: any = {
    //required: 'Please provide your zipcode/postal code.  If postal code is not applicable, enter the nearest city/town to your location', // Required field message
    validate: validatePostalCode, // Use custom validation function
  };
  const {condition} = React.useContext<Condition>(coonditionContext);

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      height: '100%', // Set the height of the parent container
    }}>
      <Typography variant="h6">
        Personal Info
      </Typography>
      <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
        Where are you located?
      </Typography>
      <Typography variant="body2" sx={{ mb: 2 }}>
        Your pin will be placed at a random location near your selected location
      </Typography>

      <Paper elevation={0} sx={{ width: '100%'}}>
        <Controller
          name="location.country"
          control={control}
          defaultValue={defaultedCountryValue?.label}
          rules={{
            required: 'Country selection required',
          }}
          render={({ field }) => (
            <Box sx={{
              alignItems: 'center',
              justifyItems: 'center',
              justifyContent: 'center',
              height: '100%',
              width: '100%',
            }}>
              <CountrySelect
                error={!!errors.location?.country}
                helperText={errors.location?.country?.message}
                value={countryObject}//{field.value}
                onChange={async (event: React.SyntheticEvent<Element, Event> | undefined | null, value: CountryType | undefined | null, reason: AutocompleteChangeReason, details?: AutocompleteChangeDetails<any> | undefined) => {
                  clearErrors("root.serverError")
                  setCountryObject(value)//? value : defaultedCountryValue)                
                  setValue('location.country', value ? value.label : '')//defaultedCountryValue.label);
                  trigger("location.country");
                  field.onChange(value ? value.label : '')
                  if (value) {
                    if (locationAutocompleteRef.current) {
                      locationAutocompleteRef.current.resetAndFocus(); // Call the reset method on LocationsAutocomplete
                    }
                    clearErrors('location.zipCode');
                  }

                }
                }
              />
              {/*`Showing map for ${searchState.formattedSearchTerm}`*/}
            </Box>
          )}
        />


        <Controller
          name="location.zipCode"
          control={control}
          //defaultValue={data.location?.zipCode}
          rules={postalCodeRules}
          render={({ field }) => (

            <LocationsAutocomplete
              {...field}
              ref={locationAutocompleteRef}
              countryCode={countryObject?.code}
              sx={{ mt: '20px', width: '100%', backgroundColor: "white" }}
              label="Enter your postal code or city"
              variant="outlined"
              error={errors.location?.zipCode}
              helperText={errors.location?.zipCode?.message}
              defaultValue={data.zipcode ? { description: data.zipcode } : undefined}
              value={place}
              onChange={(event: any) => {
                setPlace(event)
                clearErrors("root.serverError")
                setValue('location.zipCode', event?.description ?? '')//defaultedCountryValue.label);//setValue('location.zipCode', event.length ? event[0]?.description : '')//defaultedCountryValue.label);

                //trigger("location.zipCode");
                field.onChange(event?.description ?? '')//field.onChange(event.length ? event[0]?.description : '')

                if (event) {//if (event.length > 0) {
                  //   //setFocus('type')
                  //   //locationAutocompleteRef.current.focus()
                  //   const timer1 = setTimeout(() => {
                  //     typeRef.current.focus()
                  //   }, 500);
                  clearErrors('location.zipCode');
                  //reset()
                } else {
                  //clearErrors('location.zipCode');
                  //reset()
                }
              } // Use field.onChange to update the value in React Hook Form                
              }
            />


          )}
        />
      </Paper>

      <Typography ref={typeRef} variant="subtitle1" sx={{ fontWeight: 'bold', mt: 2 }} >
        Are you a {condition} patient, caregiver, or health care professional?
      </Typography>
      <Controller
        name="type"
        control={control}
        defaultValue={data.type}
        rules={{ required: "Required" }}

        render={({ field }) => {
          const userTypePinP = GetUserTypePin('P', iconStyle)
          const userTypePinC = GetUserTypePin('C', iconStyle)
          const userTypePinM = GetUserTypePin('M', iconStyle)
          const userTypePinO = GetUserTypePin('O', iconStyle)
          return (
            <div>

              <RadioGroup {...field} sx={{ padding: '10px' }}>
                <div><FormControlLabel value={'P'} control={<Radio sx={{ color: '#656EFF' }} />} label={
                  <Chip
                    label={userTypePinP.namePlural?.replace("(s)", "")}
                    icon={userTypePinP.icon}
                    size="small"
                    sx={{ padding: '5px', marginLeft: '8px', backgroundColor: GetUserTypePin('P').color }}
                  />
                } /></div>
                <div><FormControlLabel value={'C'} control={<Radio sx={{ color: '#656EFF' }} />} label={
                  <Chip
                    label={userTypePinC.namePlural?.replace("(s)", "")}
                    icon={userTypePinC.icon}
                    size="small"
                    sx={{ padding: '5px', marginLeft: '8px', backgroundColor: GetUserTypePin('C').color }}
                  />
                } /> </div>
                <div> <FormControlLabel value={'M'} control={<Radio sx={{ color: '#656EFF' }} />} label={
                  <Chip
                    label={userTypePinM.namePlural?.replace("(s)", "")}
                    icon={userTypePinM.icon}
                    size="small"
                    sx={{ padding: '5px', marginLeft: '8px', backgroundColor: GetUserTypePin('M').color }}
                  />
                } /> </div>
                <div> <FormControlLabel value={'O'} control={<Radio sx={{ color: '#656EFF' }} />} label={
                  <Chip
                    label={userTypePinO.namePlural?.replace("(s)", "")}
                    icon={userTypePinO.icon}
                    size="small"
                    sx={{ padding: '5px', marginLeft: '8px', backgroundColor: GetUserTypePin('O').color }}
                  />
                } /> </div>
              </RadioGroup>
              {errors.type && <Typography color="error">Please choose if you are a Patient, a Caregiver or a Healthcare professional</Typography>} {/* Display error message */}
            </div>
          )
        }}
      />


    </div>
  )
}

